import React, {useState, useEffect} from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import {useParams} from "react-router";
import {useSnackbar} from "notistack";
import {useDispatch} from "react-redux";

import {loadConferences} from "../../redux/conferenceRooms/actions";
import {useConferencesSelector} from "../../redux/conferenceRooms/selectors";

import JitsiMeetRoom from "./room";

import './index.css';

const WatchDemo = () => {
    const [currentRoom, setCurrentRoom] = useState(0);
    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useDispatch();
    const {conferencesLoading, roomIds} = useConferencesSelector();
    const {roomId} = useParams();

    useEffect(() => {
        if (!roomId) {
            dispatch(loadConferences(enqueueSnackbar));
        }
    }, [dispatch, enqueueSnackbar]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!roomId && roomIds.length) {
            enqueueSnackbar("joining room " + currentRoom, {variant: "warn"});
            setTimeout(incrementRoom, 15000);
        }
    }, [currentRoom, roomIds.length]); // eslint-disable-line react-hooks/exhaustive-deps

    const incrementRoom = () => {
        console.log("roomIds", roomIds);
        console.log("nextRoom", (currentRoom + 1) % roomIds.length);
        setCurrentRoom((currentRoom + 1) % roomIds.length);
    };

    return (
        <div className="h100">
            {conferencesLoading || (!roomId && (!roomIds || !roomIds.length)) ?
                <CircularProgress size={45} />
                :
                <div className="h100">
                    <JitsiMeetRoom roomId={roomId || roomIds[currentRoom]} />
                </div>
            }
        </div>
    )
};

export default WatchDemo;