/* global JitsiMeetJS */
/*eslint no-undef: "error"*/

import React, {useEffect, useRef, useState} from 'react';
import CheckIcon from '@material-ui/icons/Check';

import './index.css';

const JitsiMeetBroadcast = ({roomId, onRoomJoined}) => {
    const [joined, setJoined] = useState(null);

    useEffect(() => {
        if (roomId) {
            let room = null;
            let localTracks = null;

            JitsiMeetJS.init();

            const options = {
                hosts: {
                    domain: 'meet.jitsi',
                    muc: 'muc.meet.jitsi',
                    // focus: 'focus.meet.jit.si'
                },
                enableP2P: true,
                p2p: {
                    enabled: true,
                    preferH264: true,
                    disableH264: true,
                    useStunTurn: true
                },
                useStunTurn: true,
                bosh: `https://meet-jitsi.devsontap.com/http-bind?room=${roomId}`,
                // websocket: 'wss://meet.jit.si/xmpp-websocket',
                // bosh: `/http-bind`, // FIXME: use xep-0156 for that

                // The name of client node advertised in XEP-0115 'c' stanza
                clientNode: 'http://jitsi.org/jitsimeet'
            };
            const connection = new JitsiMeetJS.JitsiConnection(null, null, options);

            connection.addEventListener(
                JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
                function() {
                    console.log("CONNECTION SUCCESS!!");

                    JitsiMeetJS.createLocalTracks({ devices: ["audio"] }).then(tracks => {
                        localTracks = tracks;

                        console.log("got local tracks", localTracks);

                        for (let i = 0; i < localTracks.length; i++) {
                            localTracks[i].addEventListener(
                                JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
                                audioLevel => console.log(`Audio Level local: ${audioLevel}`));
                            localTracks[i].addEventListener(
                                JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,
                                () => console.log('local track muted'));
                            localTracks[i].addEventListener(
                                JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
                                () => console.log('local track stoped'));
                            localTracks[i].addEventListener(
                                JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED,
                                deviceId =>
                                    console.log(
                                        `track audio output device was changed to ${deviceId}`));
                            // if (localTracks[i].getType() === 'video') {
                            //     localTracks[i].attach(videoRef.current);
                            // } else {
                            //     localTracks[i].attach(audioRef.current);
                            // }
                        }


                        room = connection.initJitsiConference(roomId, {openBridgeChannel: true});
                        room.on(JitsiMeetJS.events.conference.TRACK_ADDED, track => {
                            console.log("======== Remote track added ========");
                            if (track.isLocal()) {
                                console.log("local track added");
                                return;
                            } else {
                                console.log("got remote track", track);
                            }
                        });
                        room.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, () => {
                            console.log("======== conference joined ======== ", roomId);
                            localTracks.forEach(track => room.addTrack(track));
                            setJoined(true);
                            if (onRoomJoined) {
                                onRoomJoined();
                            }
                            // room.addTrack(localTracks[0]);
                        });
                        room.join();
                    });

                });
            connection.addEventListener(
                JitsiMeetJS.events.connection.CONNECTION_FAILED,
                function() {
                    console.log("Connection Failed");
                });
            connection.addEventListener(
                JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
                function() {
                    console.log("Connection Disconneted");
                });

            JitsiMeetJS.mediaDevices.addEventListener(
                JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED,
                function() {
                    console.log("Device List Changed");
                });

            connection.connect();

            return () => {
                if (room) {
                    console.log("leaving room");
                    room.leave();
                }

                if (localTracks) {
                    localTracks.forEach(track => track.dispose());
                }

                console.log("disconnecting connection");
                connection.disconnect();
            }
        }
    }, [roomId]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {joined ?
                <CheckIcon />
                :
                "Joining Room..."
            }
        </div>
    )
};

JitsiMeetBroadcast.defaultProps = {
    startWithVideoMuted: false,
    startWithAudioMuted: false,
    noWatch: false
};

export default JitsiMeetBroadcast;