import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from "react-redux";
import {MeetRoom, MODE} from "@voltage-control/control-room-meet-api"

import {useConferenceByRoomSelector} from "../../../redux/conferenceRooms/selectors";
import {watchConference} from "../../../redux/conferenceRooms/actions";

import './index.css';

const JitsiMeetRoom = ({roomId, startWithVideoMuted, startWithAudioMuted, noWatch}) => {
    const dispatch = useDispatch();
    const conference = useConferenceByRoomSelector(roomId);

    const muteAll = conference ? conference.muteAll : false;

    useEffect(() => {
        if (!noWatch) {
            let cancel = null;
            dispatch(watchConference(roomId, onCancel => cancel = onCancel));
            return () => {
                if (cancel) {
                    cancel();
                }
            }
        }
    }, [roomId]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <MeetRoom
            {...{roomId, startWithVideoMuted, startWithAudioMuted}}
            useInternal={false}
            mode={MODE.FACILITATOR}
            mute={muteAll} />
    )
};

JitsiMeetRoom.defaultProps = {
    startWithVideoMuted: false,
    startWithAudioMuted: false,
    noWatch: false
};

export default JitsiMeetRoom;